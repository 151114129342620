.forgot-password-container {
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(0, 8, 29, 0.6);
}
.forgot-password-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: 30%;
    background: #fff;
    margin: auto;
    border-radius: 10px;
}
.forgot-password-header {
    display: flex;
    justify-content: flex-end;
    background: #EBF2FD;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding:5px 1rem;
}
.forgot-password-header h2 {
    cursor: pointer;
}
.forgot-password-input-container {
    padding: 10px 2rem;
}
.forgot-password-input-container p {
    margin-bottom: 5px;
    font-size: 14px;
}

.forgot-password-input-container input {
    outline: none;
    border: 1.5px solid skyblue;
    background: none;
    padding:5px 10px;
    border-radius: 20px;
    width: 100%;
}
.forgot-password-button {
    text-align: center;
    margin: 1rem 0;
}
.forgot-password-button button {
   background: #52CCFC;
   border: none;
   outline: none;
   padding: 5px 20px;
   border-radius: 20px;
}
/* ------------------------------------ Password reset screen---------------------------------- */
.password-reset-container {
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background: rgba(0, 8, 29, 0.6);
    overflow: hidden;
}
.password-reset-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: 40%;
    background: #fff;
    margin: auto;
    border-radius: 10px;
    padding:1rem 2rem 2rem;
}
.password-reset-main h2,p,input {
    color: #151e35;
}
.password-reset-main h2 {
    font-size: 20px;
    color: rgba(41, 124, 187, 1);
}
.password-reset-main p {
    margin-bottom: 5px;
    margin-top: 20px;
    color: rgba(0, 0, 0, 1);

}
.input-and-eye {
    display: flex;
    align-items: center;
    border: 1.5px solid skyblue;
    border-radius: 20px;
    padding: 5px 10px;
}
.input-and-eye input::placeholder {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 400; 
    letter-spacing: 0px;
}
.input-and-eye input[type="password"] {
    padding-right: 30px;
    font-weight: bold; 
    letter-spacing: 2px;
  }
  
.password-reset-main input {
    width: 100%;
    border: none;
    outline: none;
}
.password-reset-main button {
    background:rgba(82, 204, 252, 1);
    border: none;
    outline: none;
    padding: 5px 100px;
    border-radius: 20px;
    font-weight: 500;
}