.attraction-card-details h2 {
    font-size: 18px;
    padding: 1rem 1rem 0 1rem;
    color: #00081d;
    height: 3.5rem;
}
.view-details-rating {
    display: flex;
    justify-content: space-between;
    padding: 15px 1rem;
}
.view-details p {
    color: #00081d;
    border: 1px solid rgba(64, 182, 236, 0.533);
    border-radius: 20px;
    padding: 5px 10px;
}
.attraction-rating {
    display: flex;
    text-align: center;
    align-items: center;
}
.attraction-cancellation {
  display: flex;
  gap: 10px;
}
.attraction-cancellation .red {
    color: red;
}
.attraction-cancellation .green {
    color: green;
}
/* --------------Sub Attractions---------- */
.sub-attraction {
    padding: 0rem 2rem 1rem;
    display: flex;
    gap: 2rem;
}
.sub-attraction1 {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #25867d;
}
.sub-attraction1 h4 {
    margin-bottom: 0;
    font-size: 18px;
    color: #25867d;
}
.price-table {
    background-color: #fff;
    bottom: 3.2rem;
    box-shadow: 0 0 10px #242424;
    border-radius: 10px;
    margin-top: 1rem;
    position: absolute;
    width: 60%;
}
.attraction-details-price {
    padding-bottom: 10px;
}
.more-price-btn {
    background: none;
    outline: none;
    border: none;
    color: yellow;
    font-size: 24px;
}
.att-price-table {
    padding: 10px 20px;
    color:#000000;
    font-weight: 500;
    border:2px solid #E6E6E6;
    /* border: 1.5px solid #26db26; */
}
.none {
    display: none;
}
.attraction-details-price table {
    border-spacing: 2px;
    width: 100%;
}
.attraction-details-price table tbody tr td {
    padding: 10px;
    border: 2px solid #E6E6E6;
}
.attraction-details-price-header {
    background: #F3F8FF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 1rem 10px;
    margin-bottom: 1.5rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.attraction-details-price-header h2 {
    color: #0D8ACD;
    font-size: 20px;
}