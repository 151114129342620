.land-combos-container {
    padding: 1rem 4rem 2rem 4rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.landcombos-card {
    width: 32%;
    box-shadow: 0 0 10px #dedede;
    cursor: pointer;
}
.landcombos-card:hover {
    transform: scale(1.02);
}
.landcombos-card-image img {
    width: 100%;
    /* height: 100%; */
    min-height: 300px;
    object-fit: cover;
}
.landcombos-card-details h2 {
    height: 3.8rem;
    overflow: hidden;
    padding: 1rem;
    font-size: 18px;
    color: #00081d;
}
#select-to-date {
    opacity: 0.5;
}