.pagebanner {
    background-image: url('https://res.cloudinary.com/ddxawuqwy/image/upload/v1705385616/ndgnte8f8ukssu1tffja.svg');
    height: 200px;
    background-position-x: center;
    background-position-y: center;
    background-size: initial;
    background-repeat: no-repeat;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    background-size: cover;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.pagebanner h2 {
    text-align: center;
    font-family: "Montserrat";
    font-weight: 800;
}

.address-container {
    display: flex;
    padding: 2rem 4rem;
}
.container1 {
  width: 50%;
  box-shadow: 0 10px 10px rgba(36, 36, 36, 0.20);
}
.container1-form label {
  padding: 0;
  margin: 0;
}
.container1-form input {
  padding: 0.2rem;
}
.container1-form textarea {
  padding: 0;
  margin: 0;
  padding-left: 5px;
  height: 3rem;
}
.container1-form span {
  display: inline-block;
}
.address i {
    margin-right: 0.5rem;
}
.address b {
  font-size: 1.2rem;
}
.address h2,p{
    color: #212529;
    font-family: "Montserrat";
}
@media only screen and (max-width:768px) and (min-width:200px){
    .address-container {
        display: block;
    }
    .container1 {
      width: 100%;
      box-shadow: 0 0 10px #dedede;
    }
    .address-container {
      padding: 1rem;
    }
}
.contact-input,textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    outline: none;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
    resize: vertical;
  }
.contact-input:focus {
  outline: 2px solid skyblue;
}
textarea:focus {
  outline: 2px solid skyblue;
}  
.mobile-number-validation {
  margin-bottom: 0.5rem;
}  
  input[type=submit] {
    margin-top: 2rem;
    background-color: #52ccfc;
    color: #000000;
    padding: 6px 12px;
    font-weight: 500;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    width: 100%;
  }
  
  /* .container1 {
    padding: 20px;
  }
  .container1 h3 {
    margin-bottom: 1rem;
  } */
  .container1 h3,label {
    color: #212529;
    font-family: "Montserrat";
    margin: 0;
    padding: 0;
  }
  .container1-form {
    display: block;
    padding: 2rem;
  }
 .container1-form .react-tel-input .form-control {
    width: 100%;
  }
  /*------------------------------ New figma--------------------------- */
  .contactUs-icon-text {
    display: flex;
    gap: 1.5rem;
    align-items: center;
  }
  .contactUs-icon-text img {
    height: 20px;
    width: 20px;
  }
  .contactUs-address {
    padding: 0 2rem;
  }
  .contactUs-address h2 {
    color: #09244B;
    font-size: 24px;
  }
  .contactUs-call-email {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
  }
  .container1 {
    border-radius: 20px;
  }
  .container1 h3 {
    background: #F3F8FF;
    font-size: 24px;
    padding: 10px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .contactUs-email-mobile {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
  }
  .container1-form label {
    color: #000000;
    font-weight: 500;
    margin-bottom: 5px;
  }
  